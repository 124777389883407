<template>
    <div class="installment-dropdown">
        <button
            :data-id="dataBtnId"
            @click="toggleDropdown"
            class="dropdown-button"
        >
            {{ (selectedOption !== null) ? getLabel() : placeholder }}
            <span 
                class="arrow"
                :class="{ 'up': isOpen }"
            >
                &#9662;
            </span>
        </button>

        <input
            :data-id="dataId"
            :value="selectedOption"
            :name="name"
            hidden
        />

        <ul
            v-if="isOpen"
            :data-id="dataListId"
            class="dropdown-list"
        >
            <li
                v-for="(option, index) in options"
                :key="index"
                @click="selectOption(option)"
                :class="{ 'active': selectedOption === option }"
            >
                {{ getLabel(option) }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: false,
            default: null
        },
        dataId: {
            type: String,
            required: false,
            default: null
        },
        dataBtnId: {
            type: String,
            required: false,
            default: null
        },
        dataListId: {
            type: String,
            required: false,
            default: null
        },
        options: {
            type: Array,
            required: true,
            default: () => []
        },
        value: {
            type: [String, Number, Object],
            required: false,
            default: ''
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        optionValue: {
            type: String,
            required: false,
            default: null
        },
        optionLabel: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            isOpen: false,
            selectedOption: null
        };
    },
    mounted() {
        if (
            typeof this.value !== 'object' &&
            this.value !== null &&
            this.optionValue !== null
        ) {
            for (let index in this.options) {
                if (
                    this.options[index][this.optionValue] &&
                    this.options[index][this.optionValue] === this.value
                ) {
                    this.selectedOption = this.options[index];
                    break;
                }
            }
        }
    },
    methods: {
        getLabel(option = null) {
            if (this.optionLabel !== null && option !== null) {
                return option[this.optionLabel];
            } else if (this.optionLabel === null && option !== null) {
                return option
            }
            
            if (this.optionLabel !== null) {
                return this.selectedOption[this.optionLabel];
            }

            return this.selectedOption;
        },
        getValue(value) {
            if (
                value !== null &&
                typeof value === 'object' &&
                this.optionValue !== null &&
                value[this.optionValue]
            ) {
                return value[this.optionValue]
            }

            return value;
        },
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectOption(value) {
            this.selectedOption = value;
            this.isOpen = false;
        }
    },
    watch: {
        value(value) {
            if (
                typeof value !== 'object' &&
                this.optionValue !== null
            ) {
                for (let index in this.options) {
                    if (
                        this.options[index][this.optionValue] &&
                        this.options[index][this.optionValue] === this.value
                    ) {
                        this.selectedOption = this.options[index];
                        
                        return;
                    }
                }
            }

			this.selectedOption = value;
		},
		selectedOption(value) {
			this.$emit('input', this.getValue(value));
		}
    }
};
</script>

<style scoped>
.installment-dropdown {
    position: relative;
}

.dropdown-button {
    background: var(--charcoal-gray);
    color: var(--medium-gray);
    border-radius: 12px;
    border: solid 1px #777777;
    padding: 10px 15px;
    cursor: pointer;
    width: 100%;
    text-align: left;
}

.dropdown-button .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    float: right;
    height: 18px;
    transition: transform 0.3s ease;
}

.dropdown-button .arrow.up {
    transform: rotate(180deg);
}

.dropdown-list {
    position: absolute;
    background: var(--charcoal-gray);
    border: solid 1px #777777;
    border-radius: 12px;
    margin-top: 12px;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;

    &::-webkit-scrollbar {
        width: 4px;
    }
    
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 12px;
    }
}

.dropdown-list li {
    padding: 10px 15px;
    cursor: pointer;
}

.dropdown-list li:hover {
    background-color: #33C1D526;
}

.dropdown-list .active {
    background-color: #33C1D526;
}
</style>